import type { Environment } from '@change-corgi/core/environment';

type TypesenseNode = {
	host: string;
	protocol: string;
	port: number;
	path: string;
};

type TypensenseConfigProps = {
	apiKey: string;
	nearestNode?: TypesenseNode;
	nodes: TypesenseNode[];
};

export const typesenseConfig: Record<Environment, TypensenseConfigProps> = {
	staging: {
		// deepcode ignore HardcodedNonCryptoSecret: client-side token that is restricted to our domains.
		apiKey:
			'cmFmeEFvL3BHdVUxSlhhRmYrd0FvWkVXTCtOL1ZmNGZmczBSMW9NcEpvTT02ZFozeyJmaWx0ZXJfYnkiOiJkaXNjb3ZlcmFibGU6dHJ1ZSJ9',
		nearestNode: {
			host: 'www.staging-change.org',
			protocol: 'https',
			port: 443,
			path: '/ts',
		},
		nodes: [
			{
				host: 'www.staging-change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-0',
			},
			{
				host: 'www.staging-change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-1',
			},
			{
				host: 'www.staging-change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-2',
			},
		],
	},
	production: {
		// deepcode ignore HardcodedNonCryptoSecret: client-side token that is restricted to our domains.
		apiKey:
			'MXhHNnR3aElSRXNqMVVReWJ4azBzbjBudkdGa251cGxEZ1RUTDRrL2czdz0xa3VJeyJmaWx0ZXJfYnkiOiJkaXNjb3ZlcmFibGU6dHJ1ZSJ9',
		nearestNode: {
			host: 'www.change.org',
			protocol: 'https',
			port: 443,
			path: '/ts',
		},
		nodes: [
			{
				host: 'www.change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-0',
			},
			{
				host: 'www.change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-1',
			},
			{
				host: 'www.change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-2',
			},
		],
	},
	demo: {
		// deepcode ignore HardcodedNonCryptoSecret: client-side token that is restricted to our domains.
		apiKey:
			'cmFmeEFvL3BHdVUxSlhhRmYrd0FvWkVXTCtOL1ZmNGZmczBSMW9NcEpvTT02ZFozeyJmaWx0ZXJfYnkiOiJkaXNjb3ZlcmFibGU6dHJ1ZSJ9',
		nearestNode: {
			host: 'www.staging-change.org',
			protocol: 'https',
			port: 443,
			path: '/ts',
		},
		nodes: [
			{
				host: 'www.staging-change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-0',
			},
			{
				host: 'www.staging-change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-1',
			},
			{
				host: 'www.staging-change.org',
				protocol: 'https',
				port: 443,
				path: '/ts-2',
			},
		],
	},
	// See: https://github.com/typesense/typesense-instantsearch-adapter?tab=readme-ov-file#with-instantsearchjs
	development: {
		apiKey: 'xyz',
		nodes: [
			{
				host: 'change-typesense',
				protocol: 'http',
				port: 8108,
				path: '',
			},
		],
	},
};
