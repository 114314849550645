import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		traffic_percentage: { type: 'number' },
		user_safelist: {
			type: 'array',
			shape: { type: 'number' },
			optional: true,
		},
		user_blocklist: {
			type: 'array',
			shape: { type: 'number' },
			optional: true,
		},
	},
});

export const FCM_AUTO_FREE_PROMOTIONS_ROLLOUT = createFcmConfig('auto_free_promotions_rollout', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		traffic_percentage: 0,
		user_safelist: [],
		user_blocklist: [],
	},
});
