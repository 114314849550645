import { FCM_REVENUE_SUBS_ONE_TIME_CONTRIBUTIONS } from '@change-corgi/config/fcm/configs';

import { createFcmContext } from 'src/app/shared/contexts/fcm';
import {
	FCM_MEMBERSHIP_ANNUAL_CONTRIBUTION,
	FCM_MEMBERSHIP_BOTTOM_BANNER_ENABLED_FOR,
} from 'src/app/shared/fcm/configs';
import type { LoadedStateData } from 'src/app/shared/utils/async';

export const {
	FcmContext: MembershipBottomBannerFcmContext,
	FcmProvider: MembershipBottomBannerFcmContextProvider,
	useFcm: useMembershipBottomBannerFcm,
	useAsyncFcm: useAsyncMembershipBottomBannerFcm,
	prefetchFcm: prefetchMembershipBottomBannerFcm,
} = createFcmContext({
	name: 'MembershipBottomBannerFcmContext',
	dataProperty: 'fcm',
	configs: {
		membershipBottomBannerEnabled: FCM_MEMBERSHIP_BOTTOM_BANNER_ENABLED_FOR,
		yearlyContributionsAmountsConfig: FCM_MEMBERSHIP_ANNUAL_CONTRIBUTION,
		showOneTimeContributions: FCM_REVENUE_SUBS_ONE_TIME_CONTRIBUTIONS,
	},
	prefetch: true,
});

export type MembershipBottomBannerFcmState = ReturnType<typeof useAsyncMembershipBottomBannerFcm>;
export type MembershipBottomBannerFcm = LoadedStateData<MembershipBottomBannerFcmState>;
