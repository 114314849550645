import { googleAnalyticsTrackingIds } from '@change-corgi/config/googleAnalytics';
import type { EnvironmentUtils } from '@change-corgi/core/environment';
import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import type { GoogleAnalytics } from '@change-corgi/core/googleAnalytics';
import { createGoogleAnalytics as _createGoogleAnalytics } from '@change-corgi/core/googleAnalytics';
import type { FakeHistory } from '@change-corgi/core/react/router';
import { getLocation } from '@change-corgi/core/window';

import type { Session } from 'src/shared/session';

type Options = Readonly<{
	errorReporter: ErrorReporter;
	environment: EnvironmentUtils;
	session: Session;
	history: FakeHistory;
}>;

function getGoogleAnalyticsTrackingId(environment: EnvironmentUtils): string {
	return googleAnalyticsTrackingIds[environment.getEnvironment()] || googleAnalyticsTrackingIds.development;
}

function setupGoogleAnalytics({
	googleAnalytics,
	history,
}: {
	googleAnalytics: GoogleAnalytics;
	history: FakeHistory;
}) {
	const location = getLocation();
	let path = location.pathname + location.search + location.hash;
	googleAnalytics.ga('set', 'page', path);
	googleAnalytics.ga('send', 'pageview');
	history.listen(({ location: historyLocation }) => {
		const newPath = historyLocation.pathname + historyLocation.search + historyLocation.hash;
		if (path === newPath) return;
		path = newPath;
		googleAnalytics.ga('set', 'page', newPath);
		googleAnalytics.ga('send', 'pageview');
	});
}

export function createGoogleAnalytics({ errorReporter, session, environment, history }: Options): GoogleAnalytics {
	const googleAnalytics = _createGoogleAnalytics({
		trackingId: getGoogleAnalyticsTrackingId(environment),
		reportError: errorReporter.createSampledReporter(0.01),
	}).toggle(session.cookiePrefs.analytics);

	setupGoogleAnalytics({ googleAnalytics, history });

	return googleAnalytics;
}
