import type { Normalizer } from '@change-corgi/config/fcm/normalizers';
import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const assertAmounts = ({
	amounts,
	minAmount,
	maxAmount,
}: {
	amounts: readonly number[];
	minAmount: number;
	maxAmount: number;
}) => {
	if (amounts.length !== 4) {
		throw new Error('Amounts must have 4 amounts');
	}
	if (minAmount > Math.min(...amounts)) {
		throw new Error('minAmount must be less than or equal to minimum available amount');
	}

	if (maxAmount < Math.max(...amounts)) {
		throw new Error('maxAmount must be greater than or equal to maximum available amount');
	}
};

const shapeNormalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		enabled: { type: 'boolean' },
		amounts: {
			type: 'array',
			shape: {
				type: 'number',
			},
			optional: true,
		},
		minAmount: { type: 'number', optional: true },
		maxAmount: { type: 'number', optional: true },
	},
});

const normalizer: Normalizer<
	{ enabled: false } | { enabled: true; amounts: readonly number[]; minAmount: number; maxAmount: number }
> = (value) => {
	const configuration = shapeNormalizer(value);
	if (!configuration.enabled) return { enabled: configuration.enabled };
	if (!configuration.amounts || !configuration.minAmount || !configuration.maxAmount) {
		throw new Error('amounts, minAmount, and maxAmount must be provided');
	}
	const { amounts, minAmount, maxAmount } = configuration;
	assertAmounts({ amounts, minAmount, maxAmount });
	return {
		enabled: true,
		amounts,
		minAmount,
		maxAmount,
	};
};

export const FCM_MEMBERSHIP_ANNUAL_CONTRIBUTION = createFcmConfig('membership_annual_contribution', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: undefined,
});
