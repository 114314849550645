import React from 'react';

import { PrimitivesProvider } from 'node_modules/@change-corgi-ds-v2/primitives';

import { DSV2Link } from './components/DSV2Link';

type Props = { children: React.ReactNode };

// using a const because of a hook dep in the usePrimitive hook implementation
// TODO improve usePrimitive implementation to avoid this issue
const primitives = { a: DSV2Link };

export const DSV2PrimitivesProvider: React.FC<Props> = ({ children }: Props) => (
	<PrimitivesProvider primitives={primitives}>{children}</PrimitivesProvider>
);
