import type { Location as RouterLocation } from 'react-router';

export const START_PETITION_URL = '/start-a-petition?source_location=header';
export const MY_PETITIONS_URL = '/u/me';
export const MY_PETITIONS_DROPDOWN_URL = '/u/me?source_location=my_petitions_dropdown';
export const PETITIONS_URL = '/browse';
export const MEMBERSHIP_URL = '/member/join?source_location=member_link_header';
export const SETTINGS_URL = '/account_settings';
export const SEARCH_URL = '/search';
export const LOGIN_URL = '/login_or_join?user_flow=nav';

export function logoutUrl({ redirectTo }: { redirectTo?: RouterLocation | Location } = {}): string {
	// currently, the logout path does not handle:
	// - query params conservation => page might be different after logging out
	// - domain change => logout in dev redirects to the proxified server...
	const path = !redirectTo ? '/' : redirectTo.pathname;
	return `/logout?redirect_to=${encodeURIComponent(path)}`;
}
