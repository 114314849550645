import { Box, Flex } from '@change-corgi/design-system/layout';

import { AmountButton } from '../../../payments/components/AmountSelector/components/AmountButton';
import { AmountInput } from '../../../payments/components/AmountSelector/components/AmountInput';

import { useAmountSelector } from './hooks/useAmountSelector';

export function AmountSelector(): JSX.Element | null {
	const {
		data: {
			displayed,
			amount,
			currency,
			currencySymbol,
			currencySymbolPosition,
			decimalSeparator,
			errorMessage,
			isCustomAmount,
			isSelected,
			showFractionNumber,
			value,
			placeholder,
		},
		actions: { onChange, onClick, onFocus },
	} = useAmountSelector();

	if (!displayed) return null;

	return (
		<Box sx={{ paddingTop: '16px', paddingBottom: '6px', paddingRight: '32px' }}>
			<Flex sx={{ gap: 8, width: '100%' }}>
				<Flex sx={{ flex: '0 0 25%' }}>
					<AmountButton
						dataQaAttribute="default-amount-button"
						amount={amount}
						currency={currency}
						isSelected={isSelected}
						onClick={onClick}
					/>
				</Flex>
				<Flex sx={{ flex: 1, flexShrink: 0 }}>
					<AmountInput
						currencySymbol={currencySymbol}
						currencySymbolPosition={currencySymbolPosition}
						decimalSeparator={decimalSeparator}
						errorMessage={errorMessage}
						isCustomAmount={isCustomAmount}
						showFractionNumber={showFractionNumber}
						value={value}
						placeholder={placeholder}
						onChange={onChange}
						onFocus={onFocus}
					/>
				</Flex>
			</Flex>
		</Box>
	);
}
