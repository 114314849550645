import gql from 'graphql-tag';
import { CustomError } from 'ts-custom-error';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	AuthSharedLoginOrSignupWithGoogleMutation,
	AuthSharedLoginOrSignupWithGoogleMutationVariables,
} from './loginOrSignupByGoogle.graphql';

type LoginOrSignupWithGoogleInput = AuthSharedLoginOrSignupWithGoogleMutationVariables['loginOrSignupInput'];
type LoginOrSignupWithGoogleResponse = AuthSharedLoginOrSignupWithGoogleMutation['loginOrSignupWithGoogle'];

export class LoginOrSignupByGoogleError extends CustomError {
	readonly status?: LoginOrSignupWithGoogleResponse['status'];

	constructor(status?: LoginOrSignupWithGoogleResponse['status']) {
		super('Error on login by Google');

		this.status = status;
	}
}
export type LoginOrSignupWithGoogleContext = LoginOrSignupWithGoogleInput;

const loginOrSignupMutation = gql`
	mutation AuthSharedLoginOrSignupWithGoogle($loginOrSignupInput: LoginOrSignupWithGoogleInput!) {
		loginOrSignupWithGoogle(input: $loginOrSignupInput) {
			status
		}
	}
`;

// eslint-disable-next-line complexity
export async function loginOrSignupByGoogle(
	token: string,
	signupContext: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<'CONNECTED' | 'CREATED' | 'LOGGED_IN'> {
	let data;
	try {
		({ data } = await fetch<
			AuthSharedLoginOrSignupWithGoogleMutation,
			AuthSharedLoginOrSignupWithGoogleMutationVariables
		>({
			query: loginOrSignupMutation,
			variables: {
				loginOrSignupInput: {
					token,
					signupContext,
				},
			},
			important: true,
		}));
	} catch (err) {
		throw new LoginOrSignupByGoogleError();
	}

	if (data?.loginOrSignupWithGoogle?.status === 'CONNECTED') return 'CONNECTED';
	if (data?.loginOrSignupWithGoogle?.status === 'CREATED') return 'CREATED';
	if (data?.loginOrSignupWithGoogle?.status === 'LOGGED_IN') return 'LOGGED_IN';

	throw new LoginOrSignupByGoogleError(data?.loginOrSignupWithGoogle?.status);
}
