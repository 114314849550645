import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		profileCard: { type: 'boolean' },
		targetedPetitionList: { type: 'boolean' },
		sapToDm: { type: 'boolean' },
		responseList: { type: 'boolean' },
	},
});

export const FCM_DM_PROFILE_REDESIGN = createFcmConfig(
	'dm_profile_redesign',
	{
		normalizer: createJsonNormalizer(normalizer),
		defaultValue: {
			profileCard: false,
			targetedPetitionList: false,
			sapToDm: false,
			responseList: false,
		},
	},
	{
		merger: ({ localeValue, countryValue }) => ({
			profileCard: localeValue.profileCard && countryValue.profileCard,
			targetedPetitionList: localeValue.targetedPetitionList && countryValue.targetedPetitionList,
			sapToDm: localeValue.sapToDm && countryValue.sapToDm,
			responseList: localeValue.responseList && countryValue.responseList,
		}),
	},
);
