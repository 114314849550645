import { useEffect, useState } from 'react';

// eslint-disable-next-line no-restricted-syntax, @typescript-eslint/naming-convention
import type * as _braintree from 'braintree-web';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import { getWindow } from '@change-corgi/core/window';

import { fetchBraintreeClientData } from 'src/app/shared/api/payments';
import { loadPaypalScripts } from 'src/app/shared/utils/payments';

import type { PaypalState } from './context';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		// Use the imported typedefs for the global braintree object that was created by the braintree sdk that was loaded with a script tag
		braintree: typeof _braintree;
	}
}

export function usePaypalState(): PaypalState {
	const utilityContext = useUtilityContext();
	const [state, setState] = useState<PaypalState>({ status: 'loading' });
	const PAYPAL_LOAD_TIMEOUT = 8000; // 8 seconds to load paypal sdk

	useEffect(() => {
		(async function initializePaypal() {
			try {
				const [{ clientToken }] = await Promise.all([
					fetchBraintreeClientData(utilityContext),
					loadPaypalScripts(utilityContext.errorReporter.report),
				]);

				if (!getWindow().braintree?.client) {
					setState({ status: 'error' });
					return;
				}

				const braintreeClientInstance = await getWindow().braintree.client.create({ authorization: clientToken });
				const paypalCheckoutInstance = await getWindow().braintree.paypalCheckout.create({
					client: braintreeClientInstance,
				});

				const timeout = async (ms: number) => {
					// eslint-disable-next-line promise/avoid-new
					return new Promise((_resolve, reject) => {
						setTimeout(() => reject(new Error('Failed to load PayPal SDK: Timeout')), ms);
					});
				};
				await Promise.race([paypalCheckoutInstance.loadPayPalSDK({}), timeout(PAYPAL_LOAD_TIMEOUT)]);

				setState({ status: 'loaded', paypalCheckout: paypalCheckoutInstance });
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				void utilityContext.errorReporter.report(e);
				setState({ status: 'error' });
			}
		})();
	}, [utilityContext]);

	return state;
}
