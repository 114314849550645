import React, { forwardRef } from 'react';

import { Link } from '@change-corgi/core/react/router';

type DSV2LinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
	href: string;
};

export const DSV2Link = forwardRef(({ href, ...rest }: DSV2LinkProps, ref: React.Ref<HTMLAnchorElement>) => (
	<Link to={href} ref={ref} {...rest} />
));

DSV2Link.displayName = 'DSV2Link';
