import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

export const FCM_PROMOTED_FULFILLMENT_BY_CHANNEL = createFcmConfig('promoted_fulfillment_by_channel', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'record',
			shape: {
				type: 'object',
				shape: {
					open_rate: { type: 'number', optional: true },
					impressions_remaining_threshold: { type: 'number', optional: true },
					petition_specific_blocklist: {
						type: 'record',
						shape: {
							type: 'array',
							shape: { type: 'number' },
						},
						optional: true,
					},
					channel_blocklist: {
						type: 'array',
						shape: { type: 'number' },
						optional: true,
					},
					recommender_traffic_percentage: { type: 'number', optional: true },
					recommender_user_allowlist: {
						type: 'array',
						shape: { type: 'number' },
						optional: true,
					},
					min_recommender_percentage: { type: 'number', optional: true },
				},
			},
		}),
	),
	defaultValue: {
		petition_signer_receipt_email: {
			open_rate: 0.28,
			impressions_remaining_threshold: 1,
			petition_specific_blocklist: {},
			channel_blocklist: [],
			recommender_traffic_percentage: 0,
			recommender_user_allowlist: [],
			min_recommender_percentage: 0.1,
		},
		petition_update_email: {
			open_rate: 0.15,
			impressions_remaining_threshold: 1,
			petition_specific_blocklist: {},
			channel_blocklist: [],
			recommender_traffic_percentage: 0,
			recommender_user_allowlist: [],
			min_recommender_percentage: 0.1,
		},
		auto_reco_digest_email: {
			open_rate: 0.15,
			impressions_remaining_threshold: 1,
			petition_specific_blocklist: {},
			channel_blocklist: [],
			recommender_traffic_percentage: 0,
			recommender_user_allowlist: [],
			min_recommender_percentage: 0.1,
		},
	},
});
